import { loginHelper, tenantService, roleHelper } from '@/main';
import { Component } from 'vue-property-decorator';
import { loginService } from '@/main';
import { Member } from '../../../models/Member';
import PageRender from '../../../models/PageRender';
import { sitesModule } from '@/store/modules/site';
import { BModal } from 'bootstrap-vue';
import { memberModule } from '@/store/modules/member';

@Component
export default class LoginComponent extends PageRender {
    public tenant: any = null;
    public $refs!: {
        forgotPasswordModal: BModal;
        resetPasswordModal: BModal;
    };
    public form: any = {
        email: '',
        password: '',
        failed: false,
    };
    public registerForm: any = {
        person: { id: 0, firstName: '', insertion: '', lastName: '', sites: [] },
        emailAddress: '',
        password: '',
        needsAccount: true,
        ecology: 'infocaster',
    };
    public resetPasswordModal: any = {
        submitted: false,
        error: false,
        password: '',
        passwordCheck: '',
        submitError: false,
    };

    public forgotModal: any = {
        submitted: false,
        error: false,
        emailAddress: '',
        submitError: false,
    };

    public async mounted() {
        this.clearNotifications();
        if (loginHelper.isLoggedIn()) {
            const user = memberModule.member;
            return this.redirectToFirstSite(user);
        }

        if (this.$route.query.passwordResetCode) {
            this.$refs.resetPasswordModal.show();
        }
    }

    public async onSubmit(evt: any) {
        evt.preventDefault();
        const self = this;
        const returnUrl = this.$route.query.redirectUrl;
        self.form.failed = false;

        this.showPending('Inloggen...');
        const loginSuccess = await loginService.login(this.form.email, this.form.password);

        if (loginSuccess) {
            const user = await loginHelper.getUser();
            if (!user) {
                self.clearAndShowError(
                    'Gebruikerprofiel (nog) niet volledig. Heeft u net aangemeld, dan kan dit tot enkele minuten duren. Mocht dit niet het geval zijn neem dan contact op met de helpdesk van Recreapi',
                );
                self.form.failed = true;
                return;
            }

            memberModule.SET_MEMBER(user);
            this.clearNotifications();
            if (!user.sites || user.sites.length === 0) {
                return this.$router.push({ name: 'home' });
            }

            const resolvedRoute = returnUrl ? this.$router.resolve(returnUrl as string) : null;
            if (resolvedRoute && resolvedRoute.resolved && resolvedRoute.resolved.name && resolvedRoute.resolved.name !== 'home') {
                return this.$router.push(returnUrl as string);
            } else {
                if (roleHelper.isRecreapiHomeOwner()) {
                    return this.$router.push({
                        name: 'my-home-owner-accommodations',
                    });
                }

                return this.redirectToFirstSite(user);
            }
        } else {
            this.clearAndShowError('Inloggen mislukt.');
            self.form.failed = true;
        }
    }

    public submitPasswordForgottenModal(e): void {
        this.clearNotifications();
        this.showPending('Wachtwoord reset aanvragen...');
        if (e) {
            e.preventDefault();
        }
        this.forgotModal.submitted = true;
        const self = this;

        if (this.forgotModal.emailAddress) {
            this.forgotModal.error = false;
            loginService
                .forgotPassword(this.forgotModal.emailAddress)
                .then((response) => {
                    if (response) {
                        self.clearAndShowSuccess('Wachtwoord reset succesvol aangevraagd.');
                        self.hidePasswordForgottenModal();
                    }
                })
                .catch((reason) => {
                    self.clearAndShowError('Wachtwoord reset aanvraag mislukt.');
                    self.forgotModal.submitError = true;
                });
        } else {
            this.forgotModal.error = true;
            this.forgotModal.submitted = false;
            this.clearNotifications();
        }
    }

    public hidePasswordForgottenModal(): void {
        this.forgotModal.submitted = this.forgotModal.submitError = false;
        this.$refs.forgotPasswordModal.hide();
    }

    public async redirectToFirstSite(user: Member) {
        if (user && user.sites.length) {
            const active = user.sites[0];
            await sitesModule.setActiveSite(active);
            this.$router.push({
                name: 'site-base',
                params: { siteId: active.siteId.toString(), siteKey: active.siteKey },
            });
        }
    }

    public async submitPasswordResetModal(): Promise<void> {
        const self = this;
        if (
            this.resetPasswordModal.password &&
            this.resetPasswordModal.passwordCheck &&
            this.resetPasswordModal.password === this.resetPasswordModal.passwordCheck
        ) {
            this.resetPasswordModal.error = false;

            await loginService
                .resetPassword(this.$route.query.passwordResetCode as string, this.resetPasswordModal.password, this.resetPasswordModal.passwordCheck)
                .then((response: any) => {
                    if (response) {
                        this.hidePasswordResetModal();
                        return self.$router.push({ name: 'login' });
                    }
                });
        } else {
            this.resetPasswordModal.error = true;
            this.resetPasswordModal.submitted = false;
        }
    }

    public hidePasswordResetModal(): void {
        this.resetPasswordModal.submitted = this.resetPasswordModal.submitError = false;
        this.$refs.resetPasswordModal.hide();
    }
}
